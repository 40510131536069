import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Space,
  ThemeIcon,
  Title,
  Text,
  Group,
  Flex,
} from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import { IconCheck } from "@tabler/icons-react";

import { initialState as initialKeywordSettings } from "@components/Keyword/KeywordSettings/helpers";
import { Counter } from "@components/shared";
import { LocationSelect } from "@components/Location";

import {
  AddForm as KeywordIntervalAddForm,
  intervalValues,
} from "./KeywordSettings/Intervals";

const settingsArr = [
  {
    title: "Keyword Value",
    // text: "Supplemental text",
    trigger: "point_value_required_status",
    key_name: "point_value",
  },
  {
    title: "Interval",
    // text: "Supplemental text",
    trigger: "time_interval_required_status",
    key_name: "time_interval_required",
  },
  {
    title: "Total Uses",
    text: "How many times can this keyword be claimed?",
    trigger: "claim_interval_required_status",
    key_name: "claim_interval_required",
  },
  {
    title: "Locations",
    text: "Add your first location. You can add more later.",
    trigger: "locations_required_status",
    key_name: "locations_required",
  },
];

export default function KeywordWizardStatus({
  effortId,
  keywordDates = null,
  fetchData,
  id,
  locationId,
  organizationId,
  prize,
  status = {},
  wizardComplete = false,
  wizardSettings,
  wizardStatus,
}) {
  const [settings, setSettings] = useState(initialKeywordSettings);
  const triggeredSettings = settingsArr.filter((f) =>
    wizardComplete
      ? ![
          "point_value_required_status",
          "time_interval_required_status",
          "claim_interval_required_status",
          "locations_required_status",
        ].includes(f.trigger)
      : Object.keys(wizardStatus).includes(f.trigger)
  );

  useEffect(() => {
    fetchSettings();
  }, []);

  function fetchSettings() {
    axios
      .get(`/keywords/${id}/settings/`)
      .then(({ data }) => {
        setSettings({ ...settings, ...data.response[0].settings });
      })
      .catch((err) => {
        // setSettings({});
      });
  }

  return (
    <div>
      {!wizardComplete && (
        <React.Fragment>
          <Title order={3}>Finish setup</Title>
          <Text mb="sm">
            To gain full access, please finish the list of tasks below:
          </Text>
        </React.Fragment>
      )}
      {wizardComplete && (
        <React.Fragment>
          <Card mb="sm">
            <StatusItem
              title="Status"
              text={`Set the status of the keyword`}
              trigger="status"
              keywordId={id}
              onSuccess={fetchData}
              status={status}
              allowStatusToggle={wizardStatus.allow_status_toggle}
            />
          </Card>
        </React.Fragment>
      )}
      {triggeredSettings.map((m, i) => (
        <Card
          mb="sm"
          key={i}
          style={{
            overflow: "visible",
          }}
        >
          <StatusItem
            effortId={effortId}
            keywordDates={keywordDates}
            title={m.title}
            text={m.text}
            trigger={m.trigger}
            locationId={locationId}
            organizationId={organizationId}
            onSuccess={() => {
              fetchData();
              fetchSettings();
            }}
            settings={settings}
            keywordId={id}
            prize={prize}
            wizardComplete={wizardComplete}
            complete={wizardComplete ? false : wizardStatus[m.trigger] === true}
          />
        </Card>
      ))}
    </div>
  );
}

const initialFormValues = {
  value: 0,
  end_date: "",
  end_time: "",
  start_date: "",
  start_time: "",
};

const StatusItem = ({
  allowStatusToggle = false,
  complete = false,
  keywordId,
  keywordDates,
  onSuccess,
  settings,
  status,
  text,
  title,
  trigger,
  effortId,
}) => {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValues);

  function onSettingsSubmit(newSettings) {
    setLoading(true);

    const req = {
      keyword_id: keywordId,
      settings: newSettings,
    };

    axios
      .post(`/keywords/${keywordId}/settings/`, req)
      .then(() => {
        setFormValues(initialFormValues);
        toast.success("Saved!");
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onStatusClick(newStatus) {
    const req = {
      id: keywordId,
      status: newStatus,
    };

    setLoading(true);

    axios
      .post(`/keywords/${keywordId}/set-status/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Status changed!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onLocationSubmit() {
    const req = {
      location_id: formValues.value,
      campaign_effort_id: effortId,
    };

    setLoading(true);

    axios
      .post(`/locations/${formValues.value}/associate/`, req)
      .then(() => {
        setLoading(false);
        onSuccess();
        toast.success("Location added!");
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <div>
      <Flex align="center" gap="sm">
        <Text fw={600}>{title}</Text>
        {complete && (
          <ThemeIcon color="green" size="sm" radius="xl" variant="light">
            <IconCheck />
          </ThemeIcon>
        )}
      </Flex>
      {text && <Text>{text}</Text>}
      {!complete && (
        <React.Fragment>
          {[
            "claim_interval_required_status",
            "time_interval_required_status",
          ].includes(trigger) && (
            <React.Fragment>
              <Space mt="sm" />
              <KeywordIntervalAddForm
                dates={keywordDates}
                hiddenIntervals={[intervalValues.ever]}
                selectedInterval={
                  trigger === "claim_interval_required_status"
                    ? intervalValues.ever
                    : null
                }
                onSubmit={(e) => {
                  onSettingsSubmit(
                    makeNewSettingsHash(settings, trigger, [
                      ...settings.intervals,
                      e,
                    ])
                  );
                }}
              />
            </React.Fragment>
          )}
          {trigger === "locations_required_status" && effortId && (
            <React.Fragment>
              <Space mt="sm" />
              <LocationSelect
                label=""
                value={formValues.value}
                reqData={{
                  campaign_effort_id: effortId,
                  keyword_id: keywordId,
                  context: "keyword_setup",
                }}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    value: e,
                  })
                }
              />
              <Button
                mt="sm"
                fullWidth
                disabled={!formValues.value}
                loading={loading}
                onClick={onLocationSubmit}
              >
                Submit
              </Button>
            </React.Fragment>
          )}
          {["point_value_required_status"].includes(trigger) && (
            <React.Fragment>
              <Counter
                value={formValues.value}
                unlimited={false}
                onChange={(e) => {
                  if (e > 20) {
                    setFormValues({
                      ...formValues,
                      value: 0,
                    });
                  } else {
                    setFormValues({
                      ...formValues,
                      value: e,
                    });
                  }
                }}
                maxValue={20}
              />
              <Button
                disabled={formValues.value === 0}
                mt="sm"
                loading={loading}
                onClick={() => {
                  onSettingsSubmit(
                    makeNewSettingsHash(settings, trigger, formValues.value)
                  );
                }}
              >
                Save
              </Button>
            </React.Fragment>
          )}
          {trigger === "status" && (
            <Box mt="sm">
              <Group spacing="xs">
                {statusButtons.map((b) => (
                  <Button
                    key={b.key}
                    onClick={() => onStatusClick(b.value)}
                    color={b.color}
                    size="xs"
                    disabled={allowStatusToggle ? false : true}
                    variant={status === b.value ? "filled" : "light"}
                    loading={loading}
                  >
                    {b.text}
                  </Button>
                ))}
              </Group>
            </Box>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

function makeNewSettingsHash(settings, trigger, value) {
  switch (trigger) {
    case "claim_interval_required_status":
      return {
        ...settings,
        intervals: value,
      };
    case "time_interval_required_status":
      return {
        ...settings,
        intervals: value,
      };
    case "point_value_required_status":
      return {
        ...settings,
        point_value: value,
      };
    default:
      return settings;
  }
}

const statusButtons = [
  // { text: "created", value: 1, color: "yellow" },
  { text: "on", value: 2, color: "green" },
  { text: "off", value: 3, color: "red" },
].map((m) => ({
  ...m,
  key: m.value,
}));
