import React, { useContext, useState, useEffect } from "react";
import { Divider, Group } from "@mantine/core";

import Intervals from "./Intervals";
import { Counter } from "@components/shared";
import { CustomTooltip } from "@components/shared";
import { contestSettingsText } from "@components/Contest/helpers";
import { Context } from "./";

export default function Keywords({ beginDate, endDate, onUpdate }) {
  const { keywords: keywordData } = useContext(Context);
  const { intervals } = keywordData;

  const counterFields = [
    {
      title: contestSettingsText.keyword_use_total,
      keyName: "keyword_use_total",
      tooltipText:
        "Use Total: How many times can any given customer enter a specific keyword for this contest? If your keyword is 'Torch' and Use Total is set to 1, every customer can enter Torch once for this contest. This setting applies to every keyword attached to this contest.",
    },
    {
      title: contestSettingsText.keyword_value,
      keyName: "keyword_value",
      unlimited: false,
      tooltipText:
        "Value: How many entries is each keyword worth for this contest? If Value is set to 1, each keyword successfully redeemed will grant the user 1 entry for this contest. This setting applies to every keyword attached to this contest.",
    },
    {
      title: contestSettingsText.keyword_total_entries_allowed,
      keyName: "total_entries_allowed",
      tooltipText:
        "Total Entries Allowed: How many total entries can a user earn by redeeming keywords? If this number is set to 10, a user can earn up to 10 total entries by redeeming keywords.",
    },
  ].map((m, i) => ({
    ...m,
    key: i + 1,
    value: keywordData[m.keyName],
  }));

  return (
    <div>
      {counterFields.map((c) => (
        <Group key={c.key}>
          <div style={{ minWidth: "200px" }}>
            <Counter
              value={c.value}
              unlimited={c.unlimited}
              onChange={(e) =>
                onUpdate({
                  [c.keyName]: e,
                })
              }
            />
          </div>
          <CustomTooltip label={c.tooltipText}>
            <h3 style={{ margin: "0" }}>{c.title}</h3>
          </CustomTooltip>
        </Group>
      ))}
      <Divider mt="lg" mb="lg" />
      <Intervals
        beginDate={beginDate}
        endDate={endDate}
        items={intervals}
        title="Keyword Interval"
        onChange={(e) => onUpdate({ intervals: e })}
      />
    </div>
  );
}
