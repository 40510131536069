export const contestWizardSettings = {
  contest_keyword_entries_allowed_required: false,
  contest_keyword_use_total_required: false,
  contest_keyword_value_required: false,
  dates_required: true,
  entry_cost_required: false,
  featured_image_required: true,
  initial_entry_setting_required: false,
  prize_required: true,
  max_bid_increment_required: false,
  min_starting_bid_required: false,
  total_entries_allowed_required: false,
};

export const contestSettingsText = {
  keyword_value: "How much is a keyword worth?",
  keyword_use_total: "How many times can a keyword be used?",
  keyword_total_entries_allowed:
    "How many total entries can be earned with keywords?",
  check_in_value: "How much to award on a check-in?",
  entry_count:
    "This is the number of entries a user gets simply for registering.",
  total_entries_allowed: "Total Entries Allowed",
  initial_entry: "Initial Entry",
};
