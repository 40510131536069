import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Button, Text, Flex, Input } from "@mantine/core";

import { BasicForm } from "@components/shared";
import { initialState as contestInitialSettings } from "./ContestSettings/helpers";
import { currencyValidation } from "@util/validation";
import { dollarsToPennies } from "@util/formatters";
import timezones from "@lib/timezones";
import { todayStart, formatInputDate, dayStart, dayEnd } from "@util/dates";

import { contestWizardSettings } from "./helpers";

export default function ContestDateForm({ id, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    start_date: todayStart,
    start_time: dayStart,
    end_date: "",
    end_time: dayEnd,
  });

  function onUpdate(formData) {
    const req = { ...formData };

    axios
      .put(`/contests/${id}/`, req)
      .then(() => {
        toast.success("Contest Updated!");
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <Flex
        align="flex-end"
        gap="xs"
        direction={{
          base: "column",
          md: "row",
        }}
      >
        <Input.Wrapper label="Start Date" style={{ flexGrow: 1 }}>
          <Input
            label="Start Date"
            type="date"
            value={formState.start_date}
            onChange={(e) =>
              setFormState({
                ...formState,
                start_date: e.target.value,
              })
            }
          />
        </Input.Wrapper>
        <Input.Wrapper label="Start Time" style={{ flexGrow: 1 }}>
          <Input
            type="time"
            value={formState.start_time}
            onChange={(e) =>
              setFormState({
                ...formState,
                start_time: e.target.value,
              })
            }
          />
        </Input.Wrapper>
        <Input.Wrapper label="End Date" style={{ flexGrow: 1 }}>
          <Input
            type="date"
            value={formState.end_date}
            onChange={(e) =>
              setFormState({
                ...formState,
                end_date: e.target.value,
              })
            }
          />
        </Input.Wrapper>
        <Input.Wrapper label="End Time" style={{ flexGrow: 1 }}>
          <Input
            type="time"
            value={formState.end_time}
            onChange={(e) =>
              setFormState({
                ...formState,
                end_time: e.target.value,
              })
            }
          />
        </Input.Wrapper>
        <Button
          onClick={() => onUpdate(formState)}
          loading={loading}
          disabled={
            !formState.start_date ||
            !formState.end_date ||
            !formState.start_time ||
            !formState.end_time
          }
        >
          Save
        </Button>
      </Flex>
    </div>
  );
}
