import React, { useContext } from "react";
import { Group } from "@mantine/core";

import { Counter } from "@components/shared";
import { contestSettingsText } from "@components/Contest/helpers";
import { Context } from "./";

export default function Entry({ onUpdate }) {
  const { initial_entry: initialEntryData } = useContext(Context);

  const counterFields = [
    {
      title: contestSettingsText.entry_count,
      keyName: "entry_count",
      unlimited: false,
    },
  ].map((m, i) => ({
    ...m,
    key: i + 1,
    value: initialEntryData[m.keyName],
  }));

  return (
    <div>
      {counterFields.map((c) => (
        <Group key={c.key}>
          <Counter
            unlimited={c.unlimited}
            value={c.value}
            onChange={(e) =>
              onUpdate({
                [c.keyName]: e,
              })
            }
          />
          <h3 style={{ margin: "0" }}>{c.title}</h3>
        </Group>
      ))}
    </div>
  );
}
