import React, { createContext, useContext, useState, useEffect } from "react";
import { Space, Tabs, Text } from "@mantine/core";
import axios from "axios";

import { initialState } from "./helpers";
// import Keywords from "./Keywords";
// import Checkins from "./Checkins";
import GeneralSettings from "./GeneralSettings";
// import InitialEntry from "./InitialEntry";
// import WinnerRules from "./WinnerRules";

// const tabDefs = {
//   general: "general",
//   keywords: "keywords",
//   initial_entry: "initial entry",
//   check_ins: "check-ins",
//   winner_rules: "winner rules",
// };

export const Context = createContext(null);

export default function EffortSettings({
  beginDate,
  effortId,
  endDate,
  fetchEffort,
  wizardSettings,
}) {
  const [state, setState] = useState({
    ...initialState,
    // contestDates: {
    //   begin: beginDate,
    //   end: endDate,
    // },
  });

  useEffect(() => {
    fetchSettings();
  }, []);

  function fetchSettings() {
    axios
      .get(`/efforts/${effortId}/settings/`)
      .then(({ data }) => {
        setState({ ...state, ...data.response[0].settings });
      })
      .catch((err) => {
        // setSettings({});
      });
  }

  function updateSettings(newState) {
    const req = { campaign_effort_id: effortId, settings: newState };

    axios
      .post(`/efforts/${effortId}/settings/`, req)
      .then(() => {
        fetchSettings();
      })
      .then(() => fetchEffort())
      .catch((err) => {});
  }

  function onUpdate(newState) {
    setState({ ...state, ...newState });
    updateSettings({ ...state, ...newState });
  }

  return (
    <Context.Provider value={state}>
      <Settings
        beginDate={beginDate}
        endDate={endDate}
        fetchEffort={fetchEffort}
        onUpdate={onUpdate}
        wizardSettings={wizardSettings}
      />
      <Text size="xs" c="dimmed" mt="lg">
        *changes saved automatically
      </Text>
    </Context.Provider>
  );
}

function Settings({ onUpdate, wizardSettings }) {
  // const {
  //   activeTab,
  //   keywords: keywordData,
  //   check_ins: checkinData,
  //   initial_entry: initialEntryData,
  //   winner_rules: winnerRules,
  // } = useContext(Context);

  // const hasWizardSettings = JSON.stringify(wizardSettings) !== "{}";

  return <GeneralSettings onUpdate={(e) => onUpdate({ ...e })} />;

  // return (
  //   <div>
  //     <Tabs onChange={(e) => onUpdate({ activeTab: e })} value={activeTab}>
  //       <Tabs.List mb="xl">
  //         <Tabs.Tab value={tabDefs.general}>General</Tabs.Tab>
  //         {(!hasWizardSettings || wizardSettings.allow_keyword) && (
  //           <Tabs.Tab value={tabDefs.keywords}>Keywords</Tabs.Tab>
  //         )}
  //         <Tabs.Tab value={tabDefs.initial_entry}>Initial Entry</Tabs.Tab>
  //         {(!hasWizardSettings) && (
  //           <Tabs.Tab value={tabDefs.check_ins}>Check-Ins</Tabs.Tab>
  //         )}
  //         <Tabs.Tab value={tabDefs.winner_rules}>Winner Rules</Tabs.Tab>
  //       </Tabs.List>
  //       <Tabs.Panel value={tabDefs.general}>
  //         <GeneralSettings onUpdate={(e) => onUpdate({ ...e })} />
  //       </Tabs.Panel>
  //       <Tabs.Panel value={tabDefs.keywords}>
  //         <Keywords
  //           onUpdate={(e) => onUpdate({ keywords: { ...keywordData, ...e } })}
  //         />
  //       </Tabs.Panel>
  //       <Tabs.Panel value={tabDefs.initial_entry}>
  //         <InitialEntry
  //           onUpdate={(e) =>
  //             onUpdate({ initial_entry: { ...initialEntryData, ...e } })
  //           }
  //         />
  //       </Tabs.Panel>
  //       <Tabs.Panel value={tabDefs.check_ins}>
  //         <Checkins
  //           onUpdate={(e) => onUpdate({ check_ins: { ...checkinData, ...e } })}
  //         />
  //       </Tabs.Panel>
  //       <Tabs.Panel value={tabDefs.winner_rules}>
  //         <WinnerRules
  //           onUpdate={(e) =>
  //             onUpdate({ winner_rules: { ...winnerRules, ...e } })
  //           }
  //         />
  //       </Tabs.Panel>
  //     </Tabs>
  //   </div>
  // );
}
