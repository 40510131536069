import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, connect } from "react-redux";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import {
  AppShell,
  Burger,
  Group,
  Text,
  Title,
  Box,
  ScrollArea,
  ThemeIcon,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { Toaster } from "react-hot-toast";
import { ErrorBoundary } from "react-error-boundary";

import entityColor from "@util/entityColor";
import entityIcon from "@util/entityIcon";
import { Nameplate, NavGroup, SuspenseWrapper } from "@components/shared";
import { ManagerSetSession, ManagerCommEmail } from "@components/Manager";
import { MessagingContainer } from "./";
import InteractionWizard from "@components/InteractionWizard";
import { locationVariety } from "@components/Location/helpers";
import { CampaignReport } from "@components/Report";

import { SET_LOCATION_SETTINGS } from "@duck/locationSettings";

import {
  ArtistDetail,
  ArtistIndex,
  AssetLibrary,
  AuctionDetail,
  BulkCommunicationDetail,
  BulkCommunicationIndex,
  CampaignDetail,
  ContestDetail,
  EffortDetail,
  KeywordDetail,
  LocationDetail,
  LootItemDetail,
  ManagerDetail,
  OrganizationDetail,
  PlayDetail,
  PrizePoolDetail,
  ReportRecipeDetail,
  ReportRecipeIndex,
  SongQueryDetail,
  SongQueryIndex,
  SongIndex,
  UserIndex,
} from "@pages/Admin";
import {
  CampaignIndex,
  ContestIndex,
  Dashboard,
  EffortIndex,
  EntityDashboard,
  KeywordIndex,
  LocationSongDetail,
  LocationIndex,
  LootLockerIndex,
  ReportIndex,
  SongReportIndex,
  UserDetail,
} from "@pages/Manager";
import { Nav } from "@components/shared";

import classes from "./ManagerContainer.module.css";

const MediaQuery = ({ children }) => <div>{children}</div>;

function ManagerContainer({ locationSettings, dispatch }) {
  const loggedIn = useSelector((state) => state.manager) ? true : false;
  const managerInfo = useSelector((state) => state.manager);
  const avatarUrl = useSelector((state) => state.manager.avatar_url);
  const locationId = useSelector((state) => state.manager.location_id);
  const organizationId = useSelector((state) => state.manager.organization_id);
  const [opened, setOpened] = useState(false);

  const isStation =
    locationId && managerInfo.location_variety === locationVariety.station;

  const theme = useMantineTheme();
  const { pathname } = useLocation();

  useEffect(() => {
    fetchLocationSettings();
  }, []);

  function fetchLocationSettings() {
    if (!locationId) return;
    axios
      .get(`/locations/${locationId}/site-settings/`)
      .then(({ data }) => {
        dispatch({
          type: SET_LOCATION_SETTINGS,
          payload: data.response[0],
        });
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        //
      });
  }

  const navLinks = [
    {
      text: "Dashboard",
      to: "",
      icon: entityIcon.dashboard(),
      // color: entityColor.campaign,
    },
    {
      text: "Campaigns",
      to: "campaigns",
      icon: entityIcon.campaign(),
      color: entityColor.campaign,
    },
    {
      text: "Efforts",
      to: "efforts",
      icon: entityIcon.effort(),
      color: entityColor.effort,
    },
    {
      text: "Locations",
      to: `locations`,
      icon: entityIcon.location(),
      color: entityColor.location,
    },
    {
      text: "Users",
      to: "users",
      icon: entityIcon.user(),
      color: entityColor.user,
    },
    {
      text: "Location Profile",
      to: `locations/${locationId}`,
      icon: entityIcon.location(),
      color: entityColor.location,
    },
    {
      text: "Organization Profile",
      to: `organizations/${organizationId}`,
      icon: entityIcon.organization(),
      color: entityColor.organization,
    },
    {
      text: "Win",
      to: "contests",
      icon: entityIcon.contest(),
      color: entityColor.contest,
    },
    {
      text: "Keywords",
      to: "keywords",
      icon: entityIcon.keyword(),
      color: entityColor.keyword,
    },
    {
      text: "Reports",
      to: "reports",
      icon: entityIcon.report(),
      color: entityColor.report,
    },
    {
      text: "Asset Library",
      to: "library",
      icon: entityIcon.library(),
      color: entityColor.library,
    },
    {
      text: "Loot Locker",
      to: `loot-locker`,
      icon: entityIcon.loot(),
      color: entityColor.loot,
    },
    {
      text: "Report Recipes",
      to: "report-recipes",
      icon: entityIcon.report(),
      color: entityColor.report,
    },
    {
      text: "Sender",
      to: `bulk-communication`,
      icon: entityIcon.send(),
      color: entityColor.send,
    },
  ]
    .filter((f) =>
      !managerInfo.show_bulk_communication || organizationId
        ? !["Sender"].includes(f.text)
        : true
    )
    .filter((f) =>
      !locationId ? !["Location Profile"].includes(f.text) : true
    )
    .filter((f) =>
      !organizationId
        ? !["Organization Profile", "Locations"].includes(f.text)
        : true
    );

  if (
    loggedIn &&
    window.location.pathname.indexOf("messaging") > -1 &&
    managerInfo.location_id
  ) {
    return <MessagingContainer />;
  }

  if (locationId && !locationSettings) return null;

  if (loggedIn) {
    return (
      <div>
        <AppShell
          padding="md"
          header={{ height: 65 }}
          navbar={{
            width: 250,
            breakpoint: "sm",
            collapsed: { mobile: !opened },
          }}
          styles={(theme) => ({
            main: {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[8]
                  : theme.colors.gray[0],
            },
          })}
        >
          <AppShell.Header
            height={65}
            style={{
              background: "var(--mantine-color-dark-9)",
              padding: "5px",
            }}
          >
            <Group grow>
              <Group position="left">
                <div className={classes.burger}>
                  <Group>
                    <Burger
                      opened={opened}
                      onClick={() => setOpened(!opened)}
                      size="lg"
                      color={theme.colors.dark[1]}
                    />
                    <InteractionWizard />
                  </Group>
                </div>
                <div className={classes.logo}>
                  <Group align="center" gap="xl">
                    <img
                      src={
                        locationSettings && locationSettings.logo_url
                          ? locationSettings.logo_url
                          : "https://mixer-public-assets.s3.amazonaws.com/logo-white.png"
                      }
                      alt="Mixer"
                      style={{
                        width: "80px",
                        height: "50px",
                        objectFit: "contain",
                      }}
                    />
                    <InteractionWizard />
                  </Group>
                </div>
              </Group>
              <div className={classes.right}>
                <Group position="right" align="flexEnd" grow>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Nameplate
                      title={managerInfo.full_name}
                      avatarUrl={avatarUrl}
                    />
                  </div>
                </Group>
              </div>
            </Group>
          </AppShell.Header>
          <AppShell.Navbar pt="md" hidden={!opened} width={{ sm: 250 }}>
            <AppShell.Section grow component={ScrollArea}>
              {isStation && (
                <NavGroup
                  title="Music"
                  links={[
                    {
                      text: "Songs",
                      to: "location-songs",
                      icon: entityIcon.song(),
                      // color: entityColor.campaign,
                    },
                    {
                      text: "Song Categories",
                      to: "song-categories",
                      icon: entityIcon.song(),
                      // color: entityColor.campaign,
                    },
                    {
                      text: "Artists",
                      to: "artists",
                      icon: entityIcon.artist(),
                      // color: entityColor.campaign,
                    },
                    {
                      text: "Song Reporting",
                      to: "song-reporting",
                      icon: entityIcon.report(),
                      // color: entityColor.campaign,
                    },
                  ].map((m, i) => ({
                    ...m,
                    key: i,
                    to: `/${m.to}`,
                  }))}
                />
              )}
              <Nav
                links={navLinks.map((m) => ({
                  ...m,
                  to: `/${m.to}`,
                }))}
              />
              {isStation && (
                <UnstyledButton
                  component={"a"}
                  href={"/messaging-feed"}
                  target="_blank"
                  style={{
                    display: "block",
                    width: "100%",
                    padding: "var(--mantine-spacing-xs)",
                    backgroundColor: "transparent",
                    borderRadius: "var(--mantine-radius-sm)",
                    // "&:hover": {
                    //   backgroundColor:
                    //     theme.colorScheme === "dark"
                    //       ? theme.colors.dark[6]
                    //       : theme.colors.gray[0],
                    // },
                  }}
                >
                  <Group>
                    <ThemeIcon variant="light" color="gray">
                      {entityIcon.message()}
                    </ThemeIcon>
                    <Text size="sml">Messaging</Text>
                  </Group>
                </UnstyledButton>
              )}
              <UnstyledButton
                component={"a"}
                href={"/submit-trouble-ticket"}
                target="_blank"
                style={{
                  display: "block",
                  width: "100%",
                  padding: "var(--mantine-spacing-xs)",
                  backgroundColor: "transparent",
                  borderRadius: "var(--mantine-radius-sm)",
                  // "&:hover": {
                  //   backgroundColor:
                  //     theme.colorScheme === "dark"
                  //       ? theme.colors.dark[6]
                  //       : theme.colors.gray[0],
                  // },
                }}
              >
                <Group>
                  <ThemeIcon variant="light" color={entityColor.ticket}>
                    {entityIcon.ticket()}
                  </ThemeIcon>
                  <Text size="sml">Open support ticket</Text>
                </Group>
              </UnstyledButton>
            </AppShell.Section>
            <Box p="sm" mt="lg">
              <ManagerSetSession />
            </Box>
            <ManagerCommEmail />
          </AppShell.Navbar>
          <AppShell.Main>
            <ErrorBoundary
              fallback={
                <div>
                  <Title>That's not good!</Title>
                  <Text>Something went wrong and we're going to fix it.</Text>
                </div>
              }
            >
              {(locationId || organizationId) && (
                <React.Fragment>
                  <SuspenseWrapper>
                    <div style={{ maxWidth: "1300px", margin: "0 auto" }}>
                      <Routes>
                        <Route index element={<EntityDashboard />} />
                        <Route
                          path="/profile"
                          element={<ManagerDetail isProfile />}
                        />
                        <Route
                          path="/auctions/:id/*"
                          element={<AuctionDetail />}
                        />
                        <Route path="/keywords" element={<KeywordIndex />} />
                        <Route
                          path="/users"
                          element={<UserIndex isManager />}
                        />
                        <Route path="/users/:id/*" element={<UserDetail />} />
                        <Route
                          path="/keywords/:id/*"
                          element={<KeywordDetail />}
                        />
                        <Route
                          path="/locations/:id/*"
                          element={<LocationDetail isProfile />}
                        />
                        {organizationId && [
                          <Route
                            path="/organizations/:id/*"
                            element={<OrganizationDetail isProfile />}
                            key={1}
                          />,
                          <Route
                            path="/locations"
                            element={
                              <LocationIndex organizationId={organizationId} />
                            }
                            key={2}
                          />,
                        ]}
                        <Route
                          path="/campaigns"
                          element={
                            <CampaignIndex
                              locationId={locationId}
                              organizationId={organizationId}
                            />
                          }
                        />
                        <Route path="/efforts" element={<EffortIndex />} />
                        <Route
                          path="/reports"
                          // element={<ReportIndex isStation={isStation} />}
                          element={<CampaignReport />}
                        />
                        <Route
                          path="/campaign-report"
                          element={<CampaignReport />}
                        />
                        <Route
                          path="/loot-locker"
                          element={<LootLockerIndex />}
                        />
                        <Route
                          path="/campaigns/:id/*"
                          element={<CampaignDetail />}
                        />
                        <Route
                          path="/contests"
                          element={
                            <ContestIndex
                              locationId={locationId}
                              organizationId={organizationId}
                            />
                          }
                        />
                        <Route
                          path="/contests/:id/*"
                          element={<ContestDetail />}
                        />
                        <Route
                          path="/loot-items/:id/*"
                          element={<LootItemDetail />}
                        />
                        <Route
                          path="/efforts/:id/*"
                          element={<EffortDetail />}
                        />
                        <Route
                          path="/library"
                          element={
                            <AssetLibrary
                              locationId={locationId}
                              organizationId={organizationId}
                              isIndex
                              library
                            />
                          }
                        />
                        <Route
                          path="/prize-pools/:id/*"
                          element={<PrizePoolDetail />}
                        />
                        {managerInfo.show_bulk_communication &&
                          !managerInfo.organization_id && (
                            <Route
                              path="/bulk-communication"
                              element={<BulkCommunicationIndex />}
                            />
                          )}
                        <Route
                          path="/bulk-communication/:id/*"
                          element={<BulkCommunicationDetail />}
                        />
                        {isStation && [
                          <Route
                            path="/artists"
                            element={<ArtistIndex />}
                            key={1}
                          />,
                          <Route
                            path="/artists/:id/*"
                            element={<ArtistDetail />}
                            key={2}
                          />,
                          <Route
                            path="/location-songs"
                            element={<SongIndex locationId={locationId} />}
                            key={3}
                          />,
                          <Route
                            path="/location-songs/:id/*"
                            element={<LocationSongDetail />}
                            key={4}
                          />,
                          <Route
                            path="/song-reporting"
                            element={
                              <SongReportIndex locationId={locationId} />
                            }
                            key={5}
                          />,
                          <Route
                            path="/song-categories"
                            element={<SongQueryIndex />}
                            key={6}
                          />,
                          <Route
                            path="/song-categories/:id/*"
                            element={<SongQueryDetail />}
                            key={7}
                          />,
                          <Route
                            path="/plays/:id/*"
                            element={<PlayDetail />}
                          />,
                        ]}
                        <Route
                          path="/report-recipes"
                          element={<ReportRecipeIndex />}
                        />
                        <Route
                          path="/report-recipes/:id/*"
                          element={<ReportRecipeDetail />}
                        />
                        <Route
                          path="/manager"
                          element={<Navigate to="/campaigns" />}
                        />
                      </Routes>
                    </div>
                  </SuspenseWrapper>
                </React.Fragment>
              )}
            </ErrorBoundary>
          </AppShell.Main>
        </AppShell>
        <Toaster />
      </div>
    );
  }

  return null;
}

function mapStateToProps(state) {
  return {
    locationSettings: state.locationSettings,
  };
}
export default connect(mapStateToProps)(ManagerContainer);
