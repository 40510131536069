import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  Divider,
  Radio,
  Group,
  Modal,
  Select,
  Switch,
  TextInput,
  Text,
} from "@mantine/core";
import toast from "react-hot-toast";

import { ContestWizardModal } from "./";
import { initialState as contestInitialSettings } from "@components/Contest/ContestSettings/helpers";
import { initialPrizeSettings } from "@components/Prize/helpers";
import { contestWizardSettings } from "./helpers";

import { Contest } from "@components/Effort/EffortWizardSweepstakes";

const initialFormValues = {
  name: "",
};

const ContestAddCreate = ({
  addInternal,
  addOutside = false,
  effortId,
  effortName,
  onSuccess,
  locationId,
  organizationId,
  redirect = false,
  wizardSetup,
}) => {
  const [contestId, setContestId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [visible, setVisible] = useState(true);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [createdContestId, setCreatedContestId] = useState(null);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  function onFormSubmit() {
    const req = {
      location_id: locationId,
      organization_id: organizationId,
      campaign_effort_id: effortId,
      create_contest_for_multi: true,
      contest_data: {
        ...formValues,
        variety: 1,
        settings: {
          ...contestInitialSettings,
          check_ins: {
            ...contestInitialSettings.check_ins,
            check_in_value: 0,
          },
        },
        wizard_settings: {
          ...contestWizardSettings,
          contest_keyword_entries_allowed_required: true,
          contest_keyword_use_total_required: true,
          contest_keyword_value_required: true,
          dates_required: true,
          featured_image_required: true,
          initial_entry_setting_required: true,
          // keyword_required: wizardSetup || addOutside ? false : true,
          keyword_required: false,
          total_entries_allowed_required: true,
        },
        prize: {
          settings: initialPrizeSettings,
          name: `${formValues.name} Prize`,
          description: "",
        },
      },
    };

    if (addOutside && !organizationId) req.wait_for_keyword = true;

    setLoading(true);

    axios
      .post(`/wizard/`, req)
      .then(({ data }) => {
        if (redirect) {
          navigate(
            `/contests/${
              data.response[0].contest_id
            }?effortId=${effortId}&linkKeyword=true${
              effortName ? `&effortName=${effortName}` : ""
            }`
          );
        } else {
          setCreatedContestId(data.response[0].contest_id);
        }
        // setSearchParams(`effortId=${effortId}`);
        // setFormValues(initialFormValues);
        // toast.success("Created!");
        // setLoading(false);
        // navigate(
        //   `/contests/${data.response[0].contest_id}?effortId=${effortId}`
        // );
        // if (addOutside || addInternal) {
        //   navigate(
        //     `/contests/${data.response[0].contest_id}?effortId=${effortId}`
        //   );
        // } else {
        //   setCreatedContestId(data.response[0].contest_id);
        //   setSearchParams(`effortId=${effortId}`);
        //   setFormValues(initialFormValues);
        // }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onSelectSubmit() {
    if (addOutside && !organizationId)
      return navigate(
        `/contests/${contestId}/keywords?effortId=${effortId}&linkKeyword=true${
          effortName ? `&effortName=${effortName}` : ""
        }`
      );

    const req = {
      contest_id: contestId,
      campaign_effort_id: effortId,
    };

    setLoading(true);

    axios
      .post(`/contests/${contestId}/add-participant/`, req)
      .then(() => {
        toast.success("Added!");
        setLoading(false);
        onSuccess(contestId, true);
        toggleVisible();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function toggleVisible() {
    setVisible(false);
    setTimeout(() => {
      setVisible(true);
    }, 10);
  }

  function onModalClose() {
    setCreatedContestId(null);
    onSuccess();
    setSearchParams("");
  }

  const selectReqData = {
    pagination: false,
    ignore_joins: true,
  };

  if (addOutside || wizardSetup) {
    selectReqData.location_id_wiz_scope = locationId;
    selectReqData.organization_id_wiz_scope = organizationId;
    if (organizationId) {
      selectReqData.return_available = true;
      selectReqData.campaign_effort_id = effortId;
    }
  } else {
    selectReqData.campaign_effort_id = effortId;
  }

  if (createdContestId) {
    return (
      <div>
        <Text>Configuring created contest</Text>
        <ContestWizardModal
          contestId={createdContestId}
          fetchEffort={() => {
            onSuccess(createdContestId);
            setCreatedContestId(null);
            toggleVisible();
            setLoading(false);
            setFormValues(initialFormValues);
          }}
        />
      </div>
    );
  }

  return (
    <>
      <div>
        <Radio
          onChange={(e) => {
            setCreating(!creating);
          }}
          checked={creating ? false : true}
          label="Pick from existing contest"
          mb="sm"
        />
        <Radio
          onChange={(e) => {
            setCreating(!creating);
          }}
          checked={creating ? true : false}
          label="Create a new contest"
        />
      </div>
      <Divider mt="sm" mb="sm" />
      {visible && (
        <>
          {creating ? (
            <>
              <Modal
                opened={createdContestId ? true : false}
                onClose={onModalClose}
              >
                <Contest
                  id={createdContestId}
                  effortId={effortId}
                  locationId={locationId}
                  organizationId={organizationId}
                />
              </Modal>
              <TextInput
                id="contestNameInput"
                label="Contest Name"
                maxLength={100}
                value={formValues.name}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    name: e.target.value,
                  })
                }
              />
              <Button
                mt="xs"
                fullWidth
                onClick={onFormSubmit}
                loading={loading}
                disabled={!formValues.name}
              >
                Submit
              </Button>
            </>
          ) : (
            <>
              <ContestSelect
                label="Select a contest"
                value={contestId}
                onChange={(e) => setContestId(e)}
                reqData={selectReqData}
              />
              <Button
                onClick={onSelectSubmit}
                disabled={!contestId}
                fullWidth
                mt="xs"
                loading={loading}
              >
                Add
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ContestAddCreate;

export const ContestSelect = ({
  label = "Select a contest",
  reqData = {},
  onChange,
  disabledIds = [],
  value,
}) => {
  const [contests, setContests] = useState([]);

  useEffect(() => {
    fetchData();
    onChange(null);
  }, [JSON.stringify(reqData)]);

  function fetchData() {
    const req = {
      ...reqData,
      // pagination: false,
    };

    axios
      .post(`/retrieve-contests/`, req)
      .then(({ data }) => {
        setContests(
          data.response
            .sort((a, b) =>
              a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            )
            .map((m) => ({
              label: m.name,
              value: m.id,
            }))
        );
      })
      .catch((err) => {
        setContests([]);
      });
  }

  const formattedOptions = contests.map((m) => ({
    ...m,
    disabled: disabledIds.includes(m.value),
  }));

  return (
    <Select
      data={formattedOptions}
      label={label}
      limit={20}
      onChange={(e) => onChange(e)}
      placeholder="Select one..."
      searchable
      value={value}
    />
  );
};
