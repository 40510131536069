import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Radio,
  Card,
  Tabs,
  Grid,
  Space,
  Group,
  Title,
  Divider,
} from "@mantine/core";
import toast from "react-hot-toast";

import { Gauge, VuMeter, Speedometer } from "@components/DataViz";
import {
  EntityConfigBaseColor,
  EntityConfigIcon,
  EntityConfigLinks,
  EntityConfigColorFields,
  EntityConfigTextFields,
  EntityConfigSaveButton,
} from "@components/EntityConfig";
import { LookAndFeelManagement } from "@components/shared";

import ConfigAssets from "./ConfigAssets";
import ConfigSettings from "./ConfigSettings";

import entityColor from "@util/entityColor";
import { getUuid } from "@util/getUuid";

import { textItems, colorItems, initialConfig } from "./helpers";

export const tabs = [
  { title: "Settings", value: "settings", icon: <EntityConfigIcon.Settings /> },
  { title: "Text", value: "text", icon: <EntityConfigIcon.Text /> },
  { title: "Colors", value: "colors", icon: <EntityConfigIcon.Color /> },
  { title: "Assets", value: "assets", icon: <EntityConfigIcon.Asset /> },
  {
    title: "Components",
    value: "components",
    icon: <EntityConfigIcon.Component />,
  },
  {
    title: "Links",
    value: "links",
    icon: <EntityConfigIcon.Link />,
  },
].map((m) => ({
  ...m,
  key: getUuid(),
}));

export default function MicrositeSettings({
  campaignId,
  contestId,
  effortId,
  lookAndFeelTemplateId,
  locationId,
  onUpdate,
}) {
  const [jsonData, setJsonData] = useState(null);
  const [links, setLinks] = useState(initialConfig.links);
  const [colors, setColors] = useState(initialConfig.colors);
  const [text, setText] = useState(initialConfig.text);
  const [components, setComponents] = useState(initialConfig.components);
  const [settings, setSettings] = useState(initialConfig.settings);
  const [fetching, setFetching] = useState(true);

  const routes = {
    fetch: lookAndFeelTemplateId
      ? `/look-and-feel-templates/${lookAndFeelTemplateId}/app-configuration/`
      : campaignId
      ? `/campaigns/${campaignId}/app-configuration/`
      : effortId
      ? `/efforts/${effortId}/app-configuration/`
      : `/contests/${contestId}/app-configuration/`,
    update: lookAndFeelTemplateId
      ? `/look-and-feel-templates/${lookAndFeelTemplateId}/app-configuration/`
      : campaignId
      ? `/campaigns/${campaignId}/configuration/`
      : effortId
      ? `/efforts/${effortId}/configuration/`
      : `/contests/${contestId}/configuration/`,
  };

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setFetching(true);
    axios
      .get(routes.fetch)
      .then(({ data }) => {
        const config = data.response[0];
        if (JSON.stringify(config) !== "{}") {
          setJsonData(config);

          if (config.colors) {
            setColors({
              ...colors,
              ...config.colors,
            });
          }

          if (config.text) {
            setText({
              ...text,
              ...config.text,
            });
          }

          if (config.components) {
            setComponents({
              ...components,
              ...config.components,
            });
          }

          if (config.settings) {
            setSettings({
              ...settings,
              ...config.settings,
            });
          }

          if (config.links !== undefined) {
            setLinks([...config.links]);
          }
        } else {
          setJsonData({});
        }
      })
      .then(() => setLoading(false))
      .then(() => fetchAssets())
      .then(() => setFetching(false))
      .catch((err) => {
        setFetching(false);
      });
  }

  function updateConfig(newConfig) {
    const req = {
      configuration: { ...jsonData, ...newConfig },
    };

    axios
      .put(routes.update, req)
      .then(() => {
        fetchData();
        toast.success("Config updated!");
      })
      .then(() => onUpdate())
      .catch((err) => {
        toast.error(err);
      });
  }

  function onColorsSave() {
    updateConfig({ ...jsonData, colors });
  }

  function onTextSave() {
    updateConfig({ ...jsonData, text });
  }

  function onComponentsSave() {
    updateConfig({ ...jsonData, components });
  }

  function onLinksUpdate(newLinks) {
    updateConfig({ ...jsonData, links: [...newLinks] });
  }

  function onSettingsSave() {
    updateConfig({ ...jsonData, settings });
  }

  // if (fetching) return null;

  return (
    <div>
      {!lookAndFeelTemplateId && (
        <LookAndFeelManagement
          createReqData={{
            template_data: jsonData,
            campaign_effort_id: effortId,
            campaign_id: campaignId,
            contest_id: contestId,
          }}
          addReqData={{
            campaign_effort_id: effortId,
            campaign_id: campaignId,
            contest_id: contestId,
          }}
          fetchData={fetchData}
        />
      )}
      <Card mt="lg">
        <Tabs defaultValue="settings">
          <Tabs.List mb="lg">
            {tabs.map((tab) => (
              <Tabs.Tab key={tab.key} value={tab.value} icon={tab.icon}>
                {tab.title}
              </Tabs.Tab>
            ))}
          </Tabs.List>
          <Tabs.Panel value="text">
            <EntityConfigTextFields
              fields={textItems.filter((f) =>
                locationId
                  ? ![
                      "location_question",
                      "location_search_title",
                      "location_search_subtitle",
                    ].includes(f.name)
                  : true
              )}
              values={text}
              onChange={(e) =>
                setText({
                  ...text,
                  ...e,
                })
              }
            />
            <EntityConfigSaveButton onClick={onTextSave} />
          </Tabs.Panel>
          <Tabs.Panel value="assets">
            <ConfigAssets
              campaignId={campaignId}
              contestId={contestId}
              effortId={effortId}
              lookAndFeelTemplateId={lookAndFeelTemplateId}
              updateConfig={updateConfig}
              jsonData={jsonData}
            />
          </Tabs.Panel>
          <Tabs.Panel value="links">
            <EntityConfigLinks links={links} onUpdate={onLinksUpdate} />
          </Tabs.Panel>
          <Tabs.Panel value="colors">
            <Title mb="xs" order={4}>
              Site Button Color
            </Title>
            <EntityConfigBaseColor
              onChange={(e) => setColors({ ...colors, base: e })}
              value={colors.base}
            />
            <Space h="xl" />
            <EntityConfigColorFields
              values={colors}
              fields={colorItems}
              onChange={(e) => setColors({ ...colors, ...e })}
            />
            <EntityConfigSaveButton onClick={onColorsSave} />
          </Tabs.Panel>
          <Tabs.Panel value="components">
            <Title mb="xs" order={4}>
              Entry Progress Visualization
            </Title>
            <Grid>
              <Grid.Col span={{ base: 6 }}>
                <Card style={{ background: "var(--mantine-color-dark-8)" }}>
                  <Speedometer value={42} maxValue={500} />
                  <Radio
                    name="vizProgress"
                    c="gray"
                    label="Variant 1"
                    checked={components.gauge_variant === 1}
                    onChange={() =>
                      setComponents({
                        ...components,
                        gauge_variant: 1,
                      })
                    }
                  />
                </Card>
              </Grid.Col>
              <Grid.Col span={{ base: 6 }}>
                <Card style={{ background: "var(--mantine-color-dark-8)" }}>
                  <Gauge value={120} />
                  <Radio
                    mt="lg"
                    c="gray"
                    name="vizProgress"
                    label="Variant 2"
                    checked={components.gauge_variant === 2}
                    onChange={() =>
                      setComponents({
                        ...components,
                        gauge_variant: 2,
                      })
                    }
                  />
                </Card>
              </Grid.Col>
              <Grid.Col span={{ base: 6 }}>
                <Card style={{ background: "var(--mantine-color-dark-8)" }}>
                  <VuMeter
                    leftValue={20}
                    micrositeMeterConfig={{
                      plotBands: [
                        {
                          from: 150,
                          to: 200,
                          color: "var(--mantine-color-red-5)",
                          innerRadius: "100%",
                          outerRadius: "105%",
                        },
                      ],
                      color: "blue",
                      min: 0,
                      max: 200,
                    }}
                  />
                  <Radio
                    mt="lg"
                    c="gray"
                    name="vizProgress"
                    label="Variant 3"
                    checked={components.gauge_variant === 3}
                    onChange={() =>
                      setComponents({
                        ...components,
                        gauge_variant: 3,
                      })
                    }
                  />
                </Card>
              </Grid.Col>
            </Grid>
            <EntityConfigSaveButton onClick={onComponentsSave} />
          </Tabs.Panel>
          <Tabs.Panel value="settings">
            <ConfigSettings
              settings={settings}
              onChange={(newSettings) => setSettings(newSettings)}
              locationId={locationId}
            />
            <EntityConfigSaveButton onClick={onSettingsSave} />
          </Tabs.Panel>
        </Tabs>
      </Card>
    </div>
  );
}
