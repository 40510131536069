import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import {
  Button,
  Card,
  Group,
  Title,
  Divider,
  ThemeIcon,
  Anchor,
  Flex,
  Text,
} from "@mantine/core";
import { IconCheck, IconX, IconBell } from "@tabler/icons-react";

const btns = [
  // { text: "created", value: 1, color: "yellow" },
  { text: "on", value: 2, color: "green" },
  { text: "off", value: 3, color: "red" },
  // { text: "deleted", value: 4, color: "gray" },
].map((m) => ({
  ...m,
  key: m.value,
}));

export default function ContestStatus({
  contestId,
  fetchData,
  liveStatus = {},
  status,
  isPrizePool = false,
  isAuction = false,
}) {
  const [loading, setLoading] = useState(false);

  const noun = isAuction ? "Auction" : isPrizePool ? "Prize Pool" : "Contest";

  const items = [
    {
      text: "assets",
      value: liveStatus.has_assets,
      url: `/contests/${contestId}/assets`,
    },
    {
      text: "entries set (required)",
      value: liveStatus.has_entries_set,
      url: `/contests/${contestId}/settings`,
    },
    {
      text: "keyword value set",
      value: liveStatus.has_keyword_value_set,
      url: `/contests/${contestId}/settings`,
    },
    {
      text: "prizes set (required)",
      value: liveStatus.has_prizes,
      url: `/contests/${contestId}/prizes`,
    },
    // {
    //   text: "turned off",
    //   value: liveStatus.turned_off,
    // },
  ].filter((f) =>
    f.text === "keyword value set" && f.value === null ? false : true
  );

  function onClick(newStatus) {
    const req = {
      id: contestId,
      status: newStatus,
    };

    setLoading(true);

    axios
      .post(`/contests/${contestId}/set-status/`, req)
      .then(({ data }) => {
        if (data.response[0].redirect) {
          window.location = `/contests`;
          toast.success("Status changed!");
        } else {
          setLoading(false);
          toast.success("Status changed!");
          fetchData();
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Card>
      <SectionDivider text={`${noun} status`} />
      <Group spacing="xs">
        {btns.map((b) => (
          <Button
            key={b.key}
            onClick={() => onClick(b.value)}
            color={b.color}
            size="xs"
            variant={status === b.value ? "filled" : "light"}
          >
            {b.text}
          </Button>
        ))}
      </Group>
    </Card>
  );

  return (
    <Card>
      {!isAuction && !isPrizePool && (
        <React.Fragment>
          <SectionDivider text={`${noun} Checkup`} />
          <div>
            {items.map((item, i) => (
              <Flex mb="xs" gap="xs" align="center">
                <ThemeIcon
                  variant="filled"
                  radius="xl"
                  size="sm"
                  color={item.value ? "green" : "red"}
                >
                  {item.value ? <IconCheck /> : <IconX />}{" "}
                </ThemeIcon>
                <Text>
                  <span>
                    {item.url ? (
                      <Anchor
                        component={Link}
                        to={item.url}
                        c="dark"
                        underline="always"
                      >
                        {item.text}
                      </Anchor>
                    ) : (
                      item.text
                    )}
                  </span>
                </Text>
              </Flex>
            ))}
          </div>
        </React.Fragment>
      )}
      <SectionDivider text={`${noun} status`} />
      <Group spacing="xs">
        {btns.map((b) => (
          <Button
            key={b.key}
            onClick={() => onClick(b.value)}
            color={b.color}
            size="xs"
            variant={status === b.value ? "filled" : "light"}
            disabled={
              isAuction || isPrizePool
                ? false
                : b.value === 2 && !liveStatus.can_turn_on
            }
          >
            {b.text}
          </Button>
        ))}
      </Group>
      {!liveStatus.can_turn_on && !isAuction && !isPrizePool && (
        <Text size="xs" mt="xs" c="dimmed">
          to turn this {noun} on, please resolve the issues above
        </Text>
      )}
    </Card>
  );
}

const SectionDivider = ({ text }) => <Divider my="sm" label={text} />;
