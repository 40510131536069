import React, { createContext, useContext, useState, useEffect } from "react";
import { Space, Card, Tabs } from "@mantine/core";
import axios from "axios";

import { initialState } from "./helpers";
import Keywords from "./Keywords";
import Checkins from "./Checkins";
import GeneralSettings from "./GeneralSettings";
import InitialEntry from "./InitialEntry";
import WinnerRules from "./WinnerRules";

import { ContestSettingsLibrary } from "@components/Contest";

const tabDefs = {
  general: "general",
  keywords: "keywords",
  initial_entry: "initial entry",
  check_ins: "check-ins",
  winner_rules: "winner rules",
};

export const Context = createContext(null);

export default function ContestSettings({
  beginDate,
  canEditWinnerRules,
  contestId,
  endDate,
  fetchContest,
  wizardSettings,
}) {
  const [state, setState] = useState({
    ...initialState,
    contestDates: {
      begin: beginDate,
      end: endDate,
    },
  });

  useEffect(() => {
    fetchSettings();
  }, []);

  function fetchSettings() {
    axios
      .get(`/contests/${contestId}/settings/`)
      .then(({ data }) => {
        setState({ ...state, ...data.response[0].settings });
      })
      .catch((err) => {
        // setState(initialState);
      });
  }

  function updateSettings(newState) {
    const req = { contest_id: contestId, settings: newState };

    axios
      .post(`/contests/${contestId}/settings/`, req)
      .then(() => {
        fetchSettings();
      })
      .then(() => fetchContest())
      .catch((err) => {});
  }

  function onUpdate(newState) {
    setState({ ...state, ...newState });
    updateSettings({ ...state, ...newState });
  }

  return (
    <Context.Provider value={state}>
      <ContestSettingsLibrary
        createReqData={{
          contest_id: contestId,
          template_data: state,
        }}
        addReqData={{
          contest_id: contestId,
        }}
        fetchData={fetchSettings}
      />
      <Space mb="lg" />
      <Card>
        <Settings
          beginDate={beginDate}
          canEditWinnerRules={canEditWinnerRules}
          endDate={endDate}
          fetchContest={fetchContest}
          onUpdate={onUpdate}
          wizardSettings={wizardSettings}
        />
      </Card>
    </Context.Provider>
  );
}

function Settings({
  onUpdate,
  beginDate,
  endDate,
  wizardSettings,
  canEditWinnerRules,
}) {
  const {
    activeTab,
    keywords: keywordData,
    check_ins: checkinData,
    initial_entry: initialEntryData,
    winner_rules: winnerRules,
  } = useContext(Context);

  return (
    <div>
      <Tabs onChange={(e) => onUpdate({ activeTab: e })} value={activeTab}>
        <Tabs.List mb="xl">
          <Tabs.Tab value={tabDefs.general}>General</Tabs.Tab>
          <Tabs.Tab value={tabDefs.keywords}>Keywords</Tabs.Tab>
          <Tabs.Tab value={tabDefs.initial_entry}>Initial Entry</Tabs.Tab>
          <Tabs.Tab value={tabDefs.check_ins}>Check-Ins</Tabs.Tab>
          <Tabs.Tab value={tabDefs.winner_rules}>Winner Rules</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value={tabDefs.general}>
          <GeneralSettings onUpdate={(e) => onUpdate({ ...e })} />
        </Tabs.Panel>
        <Tabs.Panel value={tabDefs.keywords}>
          <Keywords
            beginDate={beginDate}
            endDate={endDate}
            onUpdate={(e) => onUpdate({ keywords: { ...keywordData, ...e } })}
          />
        </Tabs.Panel>
        <Tabs.Panel value={tabDefs.initial_entry}>
          <InitialEntry
            onUpdate={(e) =>
              onUpdate({ initial_entry: { ...initialEntryData, ...e } })
            }
          />
        </Tabs.Panel>
        <Tabs.Panel value={tabDefs.check_ins}>
          <Checkins
            beginDate={beginDate}
            endDate={endDate}
            onUpdate={(e) => onUpdate({ check_ins: { ...checkinData, ...e } })}
          />
        </Tabs.Panel>
        <Tabs.Panel value={tabDefs.winner_rules}>
          <WinnerRules
            canEditWinnerRules={canEditWinnerRules}
            onUpdate={(e) =>
              onUpdate({ winner_rules: { ...winnerRules, ...e } })
            }
          />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}
