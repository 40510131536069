import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "@mantine/core";

import { ContestWizardStatus } from "./";

export default function ContestWizardModal({ contestId, fetchEffort }) {
  const [info, setInfo] = useState(null);
  const [isOpen, setOpen] = useState(true);

  function onClose() {
    setOpen(false);
    fetchEffort();
  }

  useEffect(() => {
    fetchData();
  }, [contestId]);

  function fetchData() {
    axios
      .get(`/contests/${contestId}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setInfo(null);
      });
  }

  if (!info) return null;

  const { wizard_settings: wizardSettings, wizard_status: wizardStatus } = info;
  const wizardComplete = wizardStatus.wizard_complete;
  const isForDuplication = info.for_duplication;

  return (
    <div>
      <Modal
        size="xl"
        opened={isOpen}
        onClose={onClose}
        withCloseButton={false}
      >
        <ContestWizardStatus
          fetchData={fetchData}
          effortId={
            info.created_in_campaign_effort_id || info.campaign_effort_id
          }
          id={contestId}
          locationId={info.location_id}
          organizationId={info.organization_id}
          status={info.status}
          wizardComplete={wizardComplete}
          wizardSettings={wizardSettings}
          isForDuplication={isForDuplication}
          wizardStatus={wizardStatus}
          contestDates={
            info.start_date && info.end_date
              ? {
                  start_date: info.start_date,
                  start_time: info.start_time,
                  start_date_formatted: info.start_date_formatted,
                  end_date: info.end_date,
                  end_date_formatted: info.end_date_formatted,
                  end_time: info.end_time,
                }
              : null
          }
        />
      </Modal>
    </div>
  );
}
