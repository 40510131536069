import React, { createContext, useContext, useState, useEffect } from "react";
import { Card, Tabs } from "@mantine/core";
import axios from "axios";

import { initialState } from "./helpers";
import CheckIns from "./CheckIns";
import GeneralSettings from "./GeneralSettings";

const tabDefs = {
  general: "general",
  checkin: "checkin",
};

export const Context = createContext(null);

export default function LocationSettings({ locationId, fetchLocation }) {
  const [state, setState] = useState({
    ...initialState,
  });
  const [settings, setSettings] = useState({});

  useEffect(() => {
    fetchSettings();
  }, []);

  function fetchSettings() {
    axios
      .get(`/locations/${locationId}/settings/`)
      .then(({ data }) => {
        setState({ ...state, ...data.response[0] });
      })
      .catch((err) => {
        // setSettings({});
      });
  }

  function updateSettings(newState) {
    const req = { location_id: locationId, settings: newState };

    axios
      .put(`/locations/${locationId}/settings/`, req)
      .then(() => {
        fetchSettings();
      })
      .then(() => fetchLocation())
      .catch((err) => {});
  }

  function onUpdate(newState) {
    setState({ ...state, ...newState });
    updateSettings({ ...state, ...newState });
  }

  return (
    <Context.Provider value={state}>
      <Settings onUpdate={onUpdate} />
    </Context.Provider>
  );
}

function Settings({ onUpdate }) {
  const { activeTab } = useContext(Context);

  return (
    <Card>
      <Tabs onChange={(e) => onUpdate({ activeTab: e })} value={activeTab}>
        <Tabs.List mb="xl">
          <Tabs.Tab value={tabDefs.general}>General</Tabs.Tab>
          <Tabs.Tab value={tabDefs.checkin}>Check-In</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value={tabDefs.general}>
          <GeneralSettings onUpdate={(e) => onUpdate({ ...e })} />
        </Tabs.Panel>
        <Tabs.Panel value={tabDefs.checkin}>
          <CheckIns onUpdate={(e) => onUpdate({ ...e })} />
        </Tabs.Panel>
      </Tabs>
    </Card>
  );
}
